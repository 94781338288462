import  { useState, useEffect } from 'react';
import moment from 'moment';
import MasterDataService from '../../services/masterData.service';
import ReportContainerHeader from'../reports/ReportContainerHeader';
import ReportService from '../../services/report.service';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';
import BootstrapTable from 'react-bootstrap-table-next';
const API_URL = process.env.REACT_APP_BASE_URL_API;

const Reconciliation = () => { 
  const mailRoomId = localStorage.getItem('mailRoomId');
  const branch = (localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : "";

  

  
 const customStyles = {
    option: (provided: any, { isFocused, isSelected }: any) => ({
      ...provided,
      padding: 5,
      zIndex: 1,
      background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
    }),
    control: (prev: any) => ({
      ...prev,
      // none of react-select's styles are passed to <Control />
      padding: 0,
      width: "100%",
      paddingLeft:"0px",paddingRight:"0px"
  
    }),
    menu: (base: any, { isFocused, isSelected }: any) => ({
      ...base,
      zIndex: 100,
      background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
    }),
  }
  useEffect(() => { 
    const monthsData: any = [];
    for (let i = 0; i < 12; i++) {
      const month = moment().clone().subtract(i, 'months');
      const monthLabel = month.format('MMMM');
      const yearLabel = month.format('YYYY');
      const value = month.format('MM/YYYY');
      monthsData.push({ label: `${monthLabel} ${yearLabel}`, value });    
    }
    setMonths(monthsData); 
  }, [])
  
const generateoutwardreport = async () => {
    let errorMessage = "";     
  }
  const [selectedMonth, setSelectedMonth] = useState(moment().format('MM'));
  const [selectedYear, setSelectedYear] = useState(moment().format('YYYY'));
  const [selectedMonthto, setSelectedMonthto] = useState(moment().format('MM'));
  const [selectedYearto, setSelectedYearto] = useState(moment().format('YYYY'));
  const [months, setMonths] = useState<any>([]);
  const handleSelectChange = (selectedOption: any) => {
    if (selectedOption) {
      const [month, year] = selectedOption.value.split('/');
      setSelectedMonth(month);
      setSelectedYear(year);
    }
  };
  const handleSelectChangeto = (selectedOption: any) => {
    if (selectedOption) {
      const [month, year] = selectedOption.value.split('/');
      setSelectedMonthto(month);
      setSelectedYearto(year);
    }
  };

  return (
    <>
    <ReportContainerHeader />
      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}></div>
        <div className='container col-11' style={{ paddingLeft: "0px" }} >
        <div className="themeColor width" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <p style={{ marginLeft: "10px" }}>Reconciliation Summary</p>          
        </div>

<div className='col-12 row'  style={{ justifyContent: 'flex-end' }}> 
<div className='col-3 row text-right'>
  <div style={{ marginRight: '10px' }}>
    <input type='radio' name="reportType" className='zeroPadding'/> Daily
  </div>
  <div style={{ marginRight: '10px' }}>
    <input type='radio' name="reportType" className='zeroPadding'/> Monthly
  </div>
  <div>
    <input type='radio' name="reportType" className='zeroPadding'/> Yearly
  </div>
   </div> 

  <div className='col-6 row'>

  <label className='col-3 text-right'>Month/Year</label>
              <Select
               className='col-4'
                options={months}
                onChange={handleSelectChange}
                value={months.find((option:any) => option.value === `${selectedMonth}/${selectedYear}`)}
              /> 
              To
            <Select
             className='col-4'
              options={months}
              onChange={handleSelectChangeto}
              value={months.find((option:any) => option.value === `${selectedMonthto}/${selectedYearto}`)}
            />
</div>
</div> 
<div>
  {/* Datatable Goes Here */}
  <div className="table-responsive tableContainer">
  {/* <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%'}}
              keyField='id'
              striped
              hover
              condensed
              data={filteredDataDetails}
              columns={columnsDetails}
              // pagination={pagination}
              // selectRow={selectRow}
              filter={filterFactory()}
              filterPosition="top"
            /> */}
  </div>
</div>

      <div>
        <div className='btn d-flex justify-content-center'>          
  <button className="btn2 col-2" type="button" onClick={generateoutwardreport}  rel="noreferrer" ><i className="fa fa-download"></i> Download</button>  
   <Link className="btn2 col-2 col-sm-1" to=""> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</Link>
      </div>
      </div>
      </div>
      </div>
    </>
  )
}

export default Reconciliation