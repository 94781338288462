import moment from 'moment';
import { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';

function DatewiseTable(props:any) {
  const chartData = [];
  const [isOn, setIsOn] = useState(true);

  const toggleSwitch = () => {
    setIsOn(!isOn);
  };

  const today = new Date();
  const todayDay = today.getDate();

  for (let i = 1; i <= props.lastDate; i++) {
    const dayData :any = { day : i };

    props.dateWiseData.forEach((d:any) => {
      d.dates.forEach((dateData:any) => {
        if (parseInt(dateData.inwardDate.substring(0, 2)) === i) {
          dayData[d.mode] = dateData.totalShipments;
        }
      });
    });

    chartData.push(dayData);
  }

  const formatMonthYear = (selectedMonth:any, selectedYear:any) => {
    const date = new Date(selectedYear, selectedMonth - 1, 1);
    const monthName = date.toLocaleString('default', { month: 'long' });
    return `${monthName}-${selectedYear}`;
  };

  return (
    <div>
         <div className="toggle-switch" style={{ position: 'absolute', top: '2.5%', right: '9%' }}>
        <input
          type="checkbox"
          className="toggle-switch-checkbox"
          id={`toggle-switch-Example`}
          checked={isOn}
          onChange={toggleSwitch}
        />
        <label className="toggle-switch-label" htmlFor={`toggle-switch-Example`}>
          <span className="toggle-switch-inner" data-on="Graphical" data-off="Numerical" />
          <span className="toggle-switch-switch" />
        </label>
      </div>
    <div style={{ width: '100%',  position: 'relative', paddingTop: '10px' }}>
   
      
      {isOn ? (<div>
      <table className="table1 width" >
      <thead className="thead" style={{ border: '1px solid #ccc' }}>
        <tr>
          <th>Inward Count for</th>
          <th colSpan={props.lastDate} style={{ textAlign: 'left', color: '#000', backgroundColor: '#FFF', border: 'none' }}>
            {moment(props.selectedYear + '-' + props.selectedMonth + '-01').format('MMM-YYYY')}
          </th>
        </tr>
        <tr style={{ textAlign: 'center', border: '1px solid #ccc' }}>
          <th scope="col" style={{ border: '1px solid #ccc' }}>Mode</th>
          {Array.from({ length: props.lastDate }, (_, i) => (
            <th key={i} style={{ border: '1px solid #ccc' }}>{i + 1}</th>
          ))}
        </tr>
      </thead>
      <tbody className="width scroll-table" style={{ border: '1px solid grey' }}>
        {Array.isArray(props.dateWiseData) ? props.dateWiseData.map((d :any, index:any) => (
          <tr key={index} style={{ textAlign: 'center', border: '1px solid #ccc' }}>
            <td style={{ border: '1px solid #ccc', textAlign: 'left' }}>{d.mode}</td>
            {d.dates.map((dates:any, ind:any) => (
              <td key={ind} style={{ border: '1px solid #ccc' }}> {(parseInt(dates.totalShipments) !== 0) ? dates.totalShipments : '-'}</td>
            ))}
          </tr>
        )) : null}
      </tbody>
      <tfoot>
    <tr style={{ textAlign: 'center', border: '1px solid #ccc' }}>
      <td style={{ border: '1px solid #ccc', textAlign: 'left',fontWeight:'bold' }}>Total</td>
      {Array.from({ length: props.lastDate }, (_, i) => {
        const total = props.dateWiseData.reduce((acc: number, curr: any) => acc + (parseInt(curr.dates[i]?.totalShipments) || 0), 0);
        return <td key={i} style={{ border: '1px solid #ccc',fontWeight:'bold' }}>  {(total !== 0) ? total : '-'} </td>;
      })}
    </tr>
  </tfoot>
    </table>
    </div>
      ) : ( <div style={{height:"300px"}}> <ResponsiveContainer width="100%" height="95%">
      <LineChart data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="day"
          label={{
            value: `Days in ` + formatMonthYear(props.selectedMonth, props.selectedYear),
            position: 'insideBottom',
            offset: -3,
          }}
          tickFormatter={(tick) => (tick === todayDay ? `*${tick}` : tick)}
        />
        <YAxis label={{ angle: -90, position: 'insideLeft', offset: 15, value: 'Inward Count' }} />
        <Tooltip />
        <Legend verticalAlign="top" />
        {props.dateWiseData.map((d:any, index:any) => (
          <Line
            key={index}
            type="monotone"
            dataKey={d.mode}
            stroke={`hsl(${index * 50}, 70%, 50%)`}
            dot={true}
          />
        ))}
        <ReferenceLine x={todayDay} stroke="grey" strokeWidth={2} />
      </LineChart>
    </ResponsiveContainer> </div> )}
    </div>
    </div>
  );
}

export default DatewiseTable;
